<template>
  <div class="wrap">
    <div v-if="!danjuData.res_arr" class="none" style="text-align: center">
      暂无数据
    </div>
    <!-- <h5>{{ danjuData.receiv_bk[1].type_text }}</h5>
    <el-table stripe :data="danjuData.receiv_bk[1].list" style="width: 50%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="单号"
        align="center"
        prop="order_ori_code"
      ></el-table-column>
      <el-table-column
        label="应收"
        align="center"
        prop="receiv_money"
      ></el-table-column>
      <el-table-column
        label="收款"
        align="center"
        prop="real_receiv_money"
      ></el-table-column>
    </el-table>
    <h5>{{ danjuData.receiv_bk[2].type_text }}</h5>
    <el-table stripe :data="danjuData.receiv_bk[2].list" style="width: 50%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="单号"
        align="center"
        prop="order_ori_code"
      ></el-table-column>
      <el-table-column
        label="应收"
        align="center"
        prop="receiv_money"
      ></el-table-column>
      <el-table-column
        label="收款"
        align="center"
        prop="real_receiv_money"
      ></el-table-column>
    </el-table>
    <h5>{{ danjuData.receiv_bk[3].type_text }}</h5>
    <el-table stripe :data="danjuData.receiv_bk[3].list" style="width: 50%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="单号"
        align="center"
        prop="order_ori_code"
      ></el-table-column>
      <el-table-column
        label="应收"
        align="center"
        prop="receiv_money"
      ></el-table-column>
      <el-table-column
        label="收款"
        align="center"
        prop="real_receiv_money"
      ></el-table-column>
    </el-table> -->

    <div class="table">
      <div v-for="(table, index) in tables" :key="index" class="table-item">
        <div>
          <span style="line-height: 35px">{{ table.name }}:</span>
          <el-table
            v-if="table.list.length > 0"
            stripe
            :data="table.list"
            show-summary
          >
            <el-table-column
              v-for="(item, tableIndex) in table.columns"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              width=""
              align="center"
            >
              <template #default="{ row }">
                <div v-if="item.label == '单号'">
                  <span
                    style="text-decoration: underline; cursor: pointer"
                    @click="handleCheckDetail(row)"
                  >
                    {{ row[item.prop] }}
                  </span>
                  <el-tag v-for="la in row.sale_type_txt" :key="la">
                    {{ la }}
                  </el-tag>
                </div>
                <div v-else-if="item.label == '下单收款'">
                  <span style="font-weight: 800">
                    {{ row[item.prop] }}
                  </span>
                </div>
                <div v-else-if="item.label == '派送收款'">
                  <span style="font-weight: 800">
                    {{ row[item.prop] }}
                  </span>
                </div>
                <div v-else>{{ row[item.prop] }}</div>
              </template>
            </el-table-column>
          </el-table>
          <span v-else>暂无数据</span>
        </div>
      </div>
    </div>
    <yudingdan ref="yudingdan"></yudingdan>
    <xiaoshou-check ref="xiaoshou"></xiaoshou-check>
    <client1-detail ref="client1"></client1-detail>
    <client2-detail ref="client2"></client2-detail>
  </div>
</template>
<script>
  // 预订单
  import yudingdan from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  // 销售单
  import xiaoshouCheck from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  // 客户预存款单据
  import client1Detail from '@/views/project/movablepin/predeposit/depositreceipt/components/components/likeDetails.vue'
  // 客户预存货单据
  import client2Detail from '@/views/project/movablepin/preInventory/clientDoc/check/index.vue'

  export default {
    name: 'Danju',
    components: { yudingdan, xiaoshouCheck, client1Detail, client2Detail },
    props: {
      danjuData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        tables: [
          {
            name: '销售单据',
            list: [],
            columns: [
              {
                order: 1,
                label: '客户名称',
                prop: 'cust_name',
                width: '',
              },
              {
                order: 2,
                label: '单号',
                prop: 'order_code',
                width: '',
              },
              {
                order: 3,
                label: '类型',
                prop: 'order_type_text',
                width: '',
              },
              {
                order: 4,
                label: '订单金额',
                prop: 'order_amount',
                width: '',
              },
              {
                order: 5,
                label: '退货金额',
                prop: 'order_refund',
                width: '',
              },
              {
                order: 6,
                label: '应收',
                prop: 'receiv_money',
                width: '',
              },
              {
                order: 7,
                label: '下单收款(已减支出)',
                prop: 'staff_money',
                width: '',
              },
              {
                order: 7.5,
                label: '司机收款',
                prop: 'driver_money',
                width: '',
              },
              {
                order: 8,
                label: '新增欠款',
                prop: 'order_new_arrears',
                width: '',
              },
            ],
          },
          {
            name: '动销单据',
            list: [],
            columns: [
              {
                order: 1,
                label: '客户名称',
                prop: 'cust_name',
                width: '',
              },
              {
                order: 2,
                label: '单号',
                prop: 'order_code',
                width: '',
              },
              {
                order: 3,
                label: '类型',
                prop: 'order_type_text',
                width: '',
              },
              {
                order: 4,
                label: '费用',
                prop: 'order_fee',
                width: '',
              },
            ],
          },
          {
            name: '预存签约',
            list: [],
            columns: [
              {
                order: 1,
                label: '客户名称',
                prop: 'cust_name',
                width: '',
              },
              {
                order: 2,
                label: '单号',
                prop: 'order_code',
                width: '',
              },
              {
                order: 3,
                label: '类型',
                prop: 'order_type_text',
                width: '',
              },
              {
                order: 4,
                label: '状态',
                prop: 'check_status_text',
                width: '',
              },
              {
                order: 5,
                label: '签单金额',
                prop: 'order_amount',
                width: '',
              },
              {
                order: 6,
                label: '收款',
                prop: 'receiv_money',
                width: '',
              },
              {
                order: 7,
                label: '待收款',
                prop: 'order_left_amount',
                width: '',
              },
            ],
          },
          {
            name: '清欠收款',
            list: [],
            columns: [
              {
                order: 1,
                label: '客户名称',
                prop: 'cust_name',
                width: '',
              },
              {
                order: 2,
                label: '单号',
                prop: 'order_code',
                width: '',
              },
              {
                order: 3,
                label: '应收',
                prop: 'receiv_money',
                width: '',
              },
              {
                order: 4,
                label: '收款',
                prop: 'real_money',
                width: '',
              },
            ],
          },
          {
            name: '派送单据',
            list: [],
            columns: [
              {
                order: 1,
                label: '客户名称',
                prop: 'cust_name',
                width: '',
              },
              {
                order: 2,
                label: '单号',
                prop: 'order_code',
                width: '',
              },
              {
                order: 3,
                label: '类型',
                prop: 'order_type_text',
                width: '',
              },
              {
                order: 4,
                label: '订单金额',
                prop: 'order_amount',
                width: '',
              },
              {
                order: 5,
                label: '退货金额',
                prop: 'order_refund',
                width: '',
              },
              {
                order: 6,
                label: '应收',
                prop: 'receiv_money',
                width: '',
              },
              {
                order: 7,
                label: '下单收款',
                prop: 'staff_money',
                width: '',
              },
              {
                order: 7.5,
                label: '派送收款',
                prop: 'driver_money',
                width: '',
              },
              {
                order: 8,
                label: '单据欠款',
                prop: 'order_new_arrears',
                width: '',
              },
            ],
            // columns: [
            //   // {
            //   //   order: 1,
            //   //   label: '客户名称',
            //   //   prop: 'cust_name',
            //   //   width: '',
            //   // },
            //   // {
            //   //   order: 2,
            //   //   label: '单号',
            //   //   prop: 'order_code',
            //   //   width: '',
            //   // },
            //   // {
            //   //   order: 3,
            //   //   label: '订单金额',
            //   //   prop: 'receiv_money',
            //   //   width: '',
            //   // },
            //   // {
            //   //   order: 4,
            //   //   label: '派送收款',
            //   //   prop: 'real_money',
            //   //   width: '',
            //   // },
            // ],
          },
        ],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.initData()
    },
    methods: {
      initData() {
        console.log('danjuData', this.danjuData)
        if (this.danjuData.res_arr) {
          let danju = this.danjuData.res_arr
          this.tables[0].list = danju.sale
          this.tables[1].list = danju.act
          this.tables[2].list = danju.promote
          this.tables[3].list = danju.qing
          this.tables[4].list = danju.send
        }
      },
      // 点击单号查看详情 jump_id jump_type  1销售单2销售订单预订单3预存款4预存货
      handleCheckDetail(row) {
        if (row.jump_type == 1) {
          this.checkXS(row)
        } else if (row.jump_type == 2) {
          this.checkYDD(row)
        } else if (row.jump_type == 3) {
          this.checkYCK(row)
        } else if (row.jump_type == 4) {
          this.checkYCH(row)
        } else {
          this.$message.error('订单错误')
        }
      },
      // 查看预订单
      checkYDD(row) {
        this.$refs.yudingdan.showDialog()
        this.$refs.yudingdan.id = row.jump_id
      },
      // 查看销售单
      checkXS(row) {
        console.log('销售单', row)
        this.$refs.xiaoshou.id = row.jump_id
        this.$refs.xiaoshou.isshowDialog = true
      },
      // 查看客户预存货
      checkYCH(row) {
        console.log('客户预存货单据', row)
        this.$refs.client2.showDialog = true
        this.$refs.client2.id = row.jump_id
      },
      //查看客户预存款单据
      checkYCK(row) {
        console.log('客户预存款单据', row)
        this.$refs.client1.handlerData(row.jump_id)
        this.$refs.client1.outerVisible = true
      },

      // 清欠收款
      // handleCheckQQ(row) {
      //   console.log('row', row)
      //   console.log('row', row)
      //   this.$refs.xiaoshou.id = row.order_ori_id
      //   // this.$refs.xiaoshou.orderStatus = row.bill_status_text
      //   this.$refs.xiaoshou.isshowDialog = true
      // },
      // // 派送收款
      // handleCheckPS(row) {
      //   console.log('row', row)
      //   console.log('row', row)
      //   this.$refs.xiaoshou.id = row.order_ori_id
      //   // this.$refs.xiaoshou.orderStatus = row.bill_status_text
      //   this.$refs.xiaoshou.isshowDialog = true
      // },
      // // 预存收款
      // handleCheckYC(row) {
      //   console.log('row', row)
      // },
    },
  }
</script>
<style lang="scss" scoped></style>
