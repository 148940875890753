var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("h5", { staticClass: "text" }, [_vm._v("订单金额汇总")]),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "space-around" },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticClass: "demo-dynamic",
                      attrs: { inline: "", "label-position": "right" },
                    },
                    _vm._l(_vm.allData.huizong1.list, function (lists, index) {
                      return _c(
                        "el-form-item",
                        { key: index, attrs: { label: lists.key } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: { value: lists.value, disabled: "" },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("h5", { staticClass: "text" }, [_vm._v("结算汇总")]),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "space-around" },
                },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "" } },
                    _vm._l(_vm.allData.huizong2.list, function (lists, index) {
                      return _c(
                        "el-form-item",
                        { key: index },
                        [
                          lists.sign != "$"
                            ? _c(
                                "span",
                                { class: lists.sign == "-" ? "lu" : "red" },
                                [_vm._v(" " + _vm._s(lists.sign) + " ")]
                              )
                            : _c("span", [_vm._v("=")]),
                          _c(
                            "el-form-item",
                            { attrs: { label: lists.key } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "120px" },
                                attrs: { value: lists.value, disabled: "" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.allData.mx_receiv,
                    height: "500",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "收款方式", align: "center", prop: "name" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "金额", align: "center", prop: "money" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 15 } },
            [
              _c(
                "el-form",
                { attrs: { inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "应交账：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: {
                          value: _vm.allData.res_account.account_payable,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "已入账：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: {
                          value: _vm.allData.res_account.account_paid,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "待入账：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: {
                          value: _vm.allData.res_account.account_unpaid,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", [_vm._v("入账记录：")]),
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.accountData } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "入账金额",
                          align: "center",
                          prop: "money",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入入账金额" },
                                  model: {
                                    value: scope.row.money,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "money", $$v)
                                    },
                                    expression: "scope.row.money",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "入账账户", align: "center", prop: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: scope.row.id,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "id", $$v)
                                      },
                                      expression: "scope.row.id",
                                    },
                                  },
                                  _vm._l(_vm.paydata, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "时间",
                          align: "center",
                          prop: "add_time",
                        },
                      }),
                      _vm.caozuo != 2
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              prop: "money",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlerAdd(
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 添 加 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlerRemove(
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 删 除 ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2007447596
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }