var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "对账明细",
        visible: _vm.showDialog,
        width: "1100px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        fullscreen: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
          staticStyle: { "min-height": "250px" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v(" 返回对账列表 ")]
          ),
          _c("span", { staticStyle: { padding: "0px 20px" } }, [
            _vm._v("对账时间段："),
          ]),
          _vm.type == 1
            ? _c("span", [_vm._v(" " + _vm._s(_vm.startTime) + " ")])
            : _vm._e(),
          _vm.type == 1 ? _c("span", [_vm._v("——")]) : _vm._e(),
          _vm.type == 1
            ? _c("el-date-picker", {
                staticStyle: { "margin-left": "20px" },
                attrs: {
                  disabled: _vm.caozuo == 2,
                  type: "datetime",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.timeChange },
                model: {
                  value: _vm.endTime,
                  callback: function ($$v) {
                    _vm.endTime = $$v
                  },
                  expression: "endTime",
                },
              })
            : _c("span", [_vm._v(_vm._s(_vm.times))]),
          _vm.type == 1
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.caozuo != 2,
                      expression: "caozuo != 2",
                    },
                  ],
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.fetchAllData()
                    },
                  },
                },
                [_vm._v(" 更新数据 ")]
              )
            : _vm._e(),
          _c(
            "el-tabs",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleTabClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "对账交账", name: "1" } }),
              _c("el-tab-pane", { attrs: { label: "单据详情", name: "2" } }),
              _c("el-tab-pane", { attrs: { label: "车存核对", name: "3" } }),
              _c("el-tab-pane", { attrs: { label: "销售明细", name: "4" } }),
              _c("el-tab-pane", { attrs: { label: "派送明细", name: "5" } }),
            ],
            1
          ),
          _vm.showCom
            ? _c(_vm.component, {
                tag: "component",
                attrs: {
                  "all-data": _vm.allData,
                  "danju-data": _vm.danjuData,
                  caozuo: _vm.caozuo,
                  "car-data": _vm.carData,
                  "sale-data": _vm.saleData,
                  "paisong-data": _vm.paisongData,
                  "account-data": _vm.accountData,
                },
                on: {
                  addAccountList: _vm.addAccount,
                  deleteAccount: _vm.deleteAccount,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px", "text-align": "right" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.caozuo != 2,
                      expression: "caozuo != 2",
                    },
                  ],
                  attrs: { type: "success" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v(" 暂存 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.caozuo != 2,
                      expression: "caozuo != 2",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handleComplete },
                },
                [_vm._v(" 完成对账 ")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }