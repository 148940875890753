<template>
  <div class="orderTest-container">
    <el-table stripe :data="saleData.mx_list" style="width: 100%">
      <el-table-column
        label="客户名称"
        align="center"
        prop="cust_name"
      ></el-table-column>
      <el-table-column
        label="商品名称"
        align="center"
        prop="goods_name"
      ></el-table-column>
      <el-table-column
        label="规格"
        align="center"
        prop="goods_spec"
      ></el-table-column>
      <el-table-column
        label="单位"
        align="center"
        prop="unit_name"
      ></el-table-column>
      <el-table-column
        label="价格"
        align="center"
        prop="goods_price"
      ></el-table-column>
      <el-table-column
        label="数量"
        align="center"
        prop="goods_num"
      ></el-table-column>
      <el-table-column
        label="金额"
        align="center"
        prop="goods_money"
      ></el-table-column>
      <el-table-column
        label="类型"
        align="center"
        prop="goods_type"
      ></el-table-column>
    </el-table>
  </div>
</template>
<script>
  export default {
    name: 'Xiaoshou',
    components: {},
    props: {
      saleData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      console.log('销售mounted')
    },
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
