<template>
  <div class="orderTest-container">
    <h5>车存变化：</h5>
    <el-table stripe :data="carData.mx_list" style="width: 100%">
      <el-table-column
        label="品牌"
        align="center"
        prop="brand_name"
        width="120"
      ></el-table-column>
      <el-table-column
        label="商品名称"
        align="center"
        prop="goods_name"
        width="120"
      ></el-table-column>
      <el-table-column
        label="上次车存"
        align="center"
        prop="last_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="应剩车存"
        align="center"
        prop="left_num"
        width="120"
      >
        <template #default="{ row }">
          <div v-if="row.left_num != row.now_num">
            <span style="color: red">{{ row.left_num }}</span>
          </div>
          <div v-else>
            <span>{{ row.left_num }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="当前车存"
        align="center"
        prop="now_num"
        width="120"
      >
        <template #default="{ row }">
          <div v-if="row.left_num != row.now_num">
            <span style="color: red">{{ row.now_num }}</span>
          </div>
          <div v-else>
            <span>{{ row.now_num }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="采购"
        align="center"
        prop="purchase_num"
        width="120"
      ></el-table-column>

      <el-table-column
        label="调入"
        align="center"
        prop="in_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="客户退货"
        align="center"
        prop="return_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="报溢"
        align="center"
        prop="lossx_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="报损"
        align="center"
        prop="loss_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="盘点"
        align="center"
        prop="check_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="拆装"
        align="center"
        prop="split_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="调出"
        align="center"
        prop="out_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="还货"
        align="center"
        prop="pre_goods_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="预存款"
        align="center"
        prop="pre_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="兑奖"
        align="center"
        prop="prize_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="赠送"
        align="center"
        prop="gift_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="销常"
        align="center"
        prop="sale_num"
        width="120"
      ></el-table-column>
      <el-table-column
        label="其他"
        align="center"
        prop="other_num"
        width="120"
      ></el-table-column>
    </el-table>
  </div>
</template>
<script>
  export default {
    name: 'Checun',
    components: {},
    props: {
      carData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
