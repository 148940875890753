var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      !_vm.danjuData.res_arr
        ? _c(
            "div",
            { staticClass: "none", staticStyle: { "text-align": "center" } },
            [_vm._v(" 暂无数据 ")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "table" },
        _vm._l(_vm.tables, function (table, index) {
          return _c("div", { key: index, staticClass: "table-item" }, [
            _c(
              "div",
              [
                _c("span", { staticStyle: { "line-height": "35px" } }, [
                  _vm._v(_vm._s(table.name) + ":"),
                ]),
                table.list.length > 0
                  ? _c(
                      "el-table",
                      {
                        attrs: {
                          stripe: "",
                          data: table.list,
                          "show-summary": "",
                        },
                      },
                      _vm._l(table.columns, function (item, tableIndex) {
                        return _c("el-table-column", {
                          key: tableIndex,
                          attrs: {
                            prop: item.prop,
                            label: item.label,
                            width: "",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    item.label == "单号"
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "text-decoration":
                                                    "underline",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleCheckDetail(
                                                      row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(row[item.prop]) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              row.sale_type_txt,
                                              function (la) {
                                                return _c(
                                                  "el-tag",
                                                  { key: la },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(la) + " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : item.label == "下单收款"
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "800",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(row[item.prop]) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : item.label == "派送收款"
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "800",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(row[item.prop]) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _c("div", [
                                          _vm._v(_vm._s(row[item.prop])),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      1
                    )
                  : _c("span", [_vm._v("暂无数据")]),
              ],
              1
            ),
          ])
        }),
        0
      ),
      _c("yudingdan", { ref: "yudingdan" }),
      _c("xiaoshou-check", { ref: "xiaoshou" }),
      _c("client1-detail", { ref: "client1" }),
      _c("client2-detail", { ref: "client2" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }