<template>
  <el-dialog
    :modal="false"
    title="对账明细"
    :visible.sync="showDialog"
    width="1100px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
    fullscreen
  >
    <div
      v-loading="loading"
      class="orderTest-container"
      style="min-height: 250px"
    >
      <!-- <span>对账时间段：{{ 2020 - 12 - 29 }}</span>
          <span style="padding: 0px 20px">——</span>
          <el-date-picker
            v-model="time"
            style="width: 190px"
            type="datetime"
            placeholder="选择日期时间"
          ></el-date-picker>

          <el-button type="primary" style="margin-left: 20px">
            更新数据
          </el-button> -->
      <!-- <el-button v-show="caozuo != 2" type="primary">打印</el-button>
      <el-button v-show="caozuo != 2" type="primary">导出</el-button> -->
      <el-button type="primary" @click="showDialog = false">
        返回对账列表
      </el-button>
      <span style="padding: 0px 20px">对账时间段：</span>
      <span v-if="type == 1">
        {{ startTime }}
      </span>
      <span v-if="type == 1">——</span>
      <el-date-picker
        v-if="type == 1"
        v-model="endTime"
        :disabled="caozuo == 2"
        type="datetime"
        placeholder="选择日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        style="margin-left: 20px"
        @change="timeChange"
      ></el-date-picker>
      <span v-else>{{ times }}</span>
      <!-- <el-date-picker
       v-else
        v-model="endTime"
        disabled
        type="datetime"
        placeholder="选择日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        style="margin-left: 20px"
      ></el-date-picker> -->
      <el-button
        v-show="caozuo != 2"
        v-if="type == 1"
        type="primary"
        style="margin-left: 20px"
        @click="fetchAllData()"
      >
        更新数据
      </el-button>
      <el-tabs
        v-model="activeName"
        type="card"
        style="margin-top: 20px"
        @tab-click="handleTabClick"
      >
        <el-tab-pane label="对账交账" name="1"></el-tab-pane>
        <el-tab-pane label="单据详情" name="2"></el-tab-pane>
        <el-tab-pane label="车存核对" name="3"></el-tab-pane>
        <el-tab-pane label="销售明细" name="4"></el-tab-pane>
        <el-tab-pane label="派送明细" name="5"></el-tab-pane>
      </el-tabs>
      <component
        :is="component"
        v-if="showCom"
        :all-data="allData"
        :danju-data="danjuData"
        :caozuo="caozuo"
        :car-data="carData"
        :sale-data="saleData"
        :paisong-data="paisongData"
        :account-data="accountData"
        @addAccountList="addAccount"
        @deleteAccount="deleteAccount"
      ></component>
      <div style="margin-top: 20px; text-align: right">
        <el-button @click="handleExport">导出</el-button>
        <el-button v-show="caozuo != 2" type="success" @click="handleSave">
          暂存
        </el-button>
        <el-button v-show="caozuo != 2" type="primary" @click="handleComplete">
          完成对账
        </el-button>
        <!-- <el-button v-show="caozuo != 2" type="warning" @click="handleDelete">
          对账作废
        </el-button> -->
        <el-button @click="close">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { dateForm } from '@/utils/Time'

  import { downloadFile, postAction } from '@/api/Employee'
  import Duizhang from './duizhangCom/duizhang'
  import Danju from './duizhangCom/danju'
  import Checun from './duizhangCom/checun'
  import Xiaoshou from './duizhangCom/xiaoshou'
  import Paisong from './duizhangCom/paisong'
  export default {
    name: 'DuiZhang',
    components: { Duizhang, Danju, Checun, Xiaoshou, Paisong },
    data() {
      return {
        id: null,
        duizhang_id: null,
        showDialog: false,
        caozuo: 1,
        type: 1,
        times: '',
        loading: false,
        endTime: '',
        timebk: '',
        startTime: '',
        activeName: '1',
        allData: {},
        danjuData: {},
        saleData: {},
        accountData: [],
        paisongData: {},
        component: 'Duizhang',
        showCom: false,
        url: {
          department: '/baseAdmin/depart/index',
          Roles: '/baseAdmin/common/role-list',
          data: '/saleAdmin/duizhang/index',
          duizhang: '/saleAdmin/duizhang/un-base-detail',
          jixu: '/saleAdmin/duizhang/base-detail',
          danju: '/saleAdmin/duizhang/un-bill-detail-new',
          danju2: '/saleAdmin/duizhang/bill-detail-new',
          car: '/saleAdmin/duizhang/un-car-detail',
          car2: '/saleAdmin/duizhang/car-detail',
          sale: '/saleAdmin/duizhang/un-sale-detail',
          sale2: '/saleAdmin/duizhang/sale-detail',
          paisong: '/saleAdmin/duizhang/un-send-detail',
          paisong2: '/saleAdmin/duizhang/send-detail',
          // 入账列表
          account: '/saleAdmin/duizhang/ruzhang-list',
          // /saleAdmin/duizhang/finish
          // 完成 对账
        },
      }
    },
    computed: {
      // startTime() {
      //   if (this.allData.res_info) {
      //     return this.allData.res_info.times
      //   } else {
      //     return ''
      //   }
      // },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fid = JSON.parse(JSON.stringify(this.id))
          this.fetchAllData()
        } else {
          this.caozuo = 1
          this.accountData = []
          this.showCom = false
        }
      },
      type(e) {
        console.log(e, 'type变化')
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 获取所有数据
      fetchAllData() {
        this.showCom = false
        this.loading = true
        // this.fetchData().then((res) => {
        //   console.log('asdasd', res)
        // })
        // this.fetchData2()
        Promise.all([
          this.fetchData(),
          this.fetchData2(),
          this.fetchData3(),
          this.fetchData4(),
          this.fetchData5(),
          this.fetchAccount(),
        ]).then((res) => {
          this.showCom = true
          this.loading = false
          this.timebk = JSON.parse(JSON.stringify(this.endTime))
        })
      },
      // 对账交账数据
      async fetchData() {
        if (this.type == 1) {
          console.log('对账')
          let { data } = await postAction(this.url.duizhang, {
            user_id: this.id,
            end_time: this.endTime,
          })
          this.allData = data
          console.log('111', this.allData.res_info.times)
          this.startTime = this.allData.res_info.times.split(' -- ')[0]
          this.endTime = this.allData.res_info.times.split(' -- ')[1]
          console.log('对账交账数据', data)
        } else {
          console.log('继续数据')
          let { data } = await postAction(this.url.jixu, {
            duizhang_id: this.duizhang_id,
          })
          this.allData = data
          console.log('eee', this.allData.res_info.times)
          this.times = this.allData.res_info.times
          // this.startTime = this.allData.res_info.times.split('--')[0]
          // this.endTime = this.allData.res_info.times.split('--')[1]
          console.log('对账交账数据', data)
        }
      },
      // 入账列表获取
      async fetchAccount() {
        console.log('入账列表获取')
        if (this.type != 1) {
          let { data } = await postAction(this.url.account, {
            duizhang_id: this.duizhang_id,
          })
          this.accountData = data
          console.log('入账数据', data)
        }
        if (this.accountData.length == 0) {
          this.accountData.push({
            id: '',
            money: '',
            add_time: this.endTime,
            edit: true,
          })
        }
      },
      // 单据数据
      async fetchData2() {
        if (this.type == 1) {
          let { data } = await postAction(this.url.danju, {
            user_id: this.id,
            end_time: this.endTime,
          })
          this.danjuData = data
          console.log('单据数据对账', data)
        } else {
          let { data } = await postAction(this.url.danju2, {
            duizhang_id: this.duizhang_id,
          })
          this.danjuData = data
          console.log('单据数据继续', data)
        }
      },
      // 车存核算
      async fetchData3() {
        if (this.type == 1) {
          let { data } = await postAction(this.url.car, {
            user_id: this.id,
            end_time: this.endTime,
          })
          if (data.constructor == Array) {
            data = {}
          }
          this.carData = data
          console.log('车存核算数据', data)
        } else {
          let { data } = await postAction(this.url.car2, {
            duizhang_id: this.duizhang_id,
          })
          this.carData = data
          console.log('车存核算数据', data)
        }
      },
      // 销售明细
      async fetchData4() {
        if (this.type == 1) {
          let { data } = await postAction(this.url.sale, {
            user_id: this.id,
            end_time: this.endTime,
          })
          this.saleData = data
          console.log('销售明细数据', data)
        } else {
          let { data } = await postAction(this.url.sale2, {
            duizhang_id: this.duizhang_id,
          })
          this.saleData = data
          console.log('销售明细数据', data)
        }
      },
      // 派送明细
      async fetchData5() {
        if (this.type == 1) {
          let { data } = await postAction(this.url.paisong, {
            user_id: this.id,
            end_time: this.endTime,
          })
          this.paisongData = data
          console.log('派送明细数据', data)
        } else {
          let { data } = await postAction(this.url.paisong2, {
            duizhang_id: this.duizhang_id,
          })
          this.paisongData = data
          console.log('派送明细数据', data)
        }
      },
      // 切换tab
      handleTabClick(tab) {
        console.log(tab.label)
        if (tab.label == '对账交账') {
          this.component = 'Duizhang'
        } else if (tab.label == '单据详情') {
          this.component = 'Danju'
        } else if (tab.label == '车存核对') {
          this.component = 'Checun'
        } else if (tab.label == '销售明细') {
          this.component = 'Xiaoshou'
        } else if (tab.label == '派送明细') {
          this.component = 'Paisong'
        }
      },
      //hhy新加方法以前在add里面嵌套
      handlersave(data) {
        postAction('/saleAdmin/duizhang/ruzhang-save', data)
          .then(async (res) => {
            console.log(res)
            this.$message.success('保存成功')
            // this.type = 2
            // this.$set(this, 'type', 2)
            console.log('赋值了吗', this.type)
            this.fetchData()
            let { data } = await postAction(this.url.account, {
              duizhang_id: this.duizhang_id,
            })
            this.accountData = data
            this.$emit('refresh')
            // this.duizhangID = res.data.id
          })

          .catch((err) => {
            console.log(err)
          })
      },
      // 暂存
      async handleSave() {
        this.loading = true
        let promise = new Promise((result, reject) => {
          let data
          if (this.type == 1) {
            console.log('待对账')
            // 先创建对账
            postAction('/saleAdmin/duizhang/add', {
              pay_data: JSON.stringify(this.accountData),
              user_id: this.id,
              end_time: this.endTime,
            })
              .then((r) => {
                console.log('创建完成', r)
                this.$message.success('创建完成')
                this.duizhang_id = r.data.id
                data = {
                  duizhang_id: this.duizhang_id,
                  pay_data: JSON.stringify(this.accountData),
                }
                this.type = 2
                this.$set(this, 'type', 2)
                result(r)
                this.$emit('refresh')
                this.loading = false
              })
              .catch((err) => {
                this.loading = false
                console.log(err)
              })
            //hhy修改问题//又修改21.7.3
            // await this.handlersave(data)
          } else {
            console.log('对账中')
            let data = {
              duizhang_id: this.duizhang_id,
              pay_data: JSON.stringify(this.accountData),
            }
            postAction('/saleAdmin/duizhang/ruzhang-save', data)
              .then(async (res) => {
                console.log(res)
                this.$message.success('保存成功')
                this.type = 2
                this.fetchData()
                this.$emit('refresh')
                let { data } = await postAction(this.url.account, {
                  duizhang_id: this.duizhang_id,
                })
                this.accountData = data
                // this.duizhangID = res.data.id
                this.loading = false
                result(res)
              })
              .catch((err) => {
                this.loading = false
                console.log(err)
              })
          }
        })
        return promise
      },
      // 完成对账   type 1 对账   2继续
      async handleComplete() {
        this.loading = true
        this.handleSave().then(async (res) => {
          let payData = []
          console.log(res, 'save')

          this.accountData.forEach((item) => {
            if (item.id !== '' && item.money !== '') {
              payData.push(item)
            }
          })
          if (this.type == 1) {
            try {
              let res = await postAction('/saleAdmin/duizhang/add', {
                user_id: this.id,
                end_time: this.endTime,
                // duizhang_id: this.duizhang_id,
                pay_data: JSON.stringify(payData),
              })
              id = res.data.id
              // this.duizhang_id = r.data.id
              this.fid = res.data.id
              this.$emit('refresh')
            } catch (err) {
              console.log('错误', err)
              this.loading = false
            }
          }

          let { data } = await postAction('/saleAdmin/duizhang/finish', {
            duizhang_id: this.duizhang_id,
          })
          this.$message.success('完成对账')
          this.close()
          this.$emit('refresh')
          console.log('完成对账')
          this.loading = false
        })
      },
      // 作废
      async handleDelete() {
        console.log('作废对账')
        let { data } = await postAction('/saleAdmin/duizhang/scrap', {
          duizhang_id: this.id,
        })
        this.$message.success('作废对账')
        this.close()
        this.$emit('refresh')
      },
      // 添加一条入账
      addAccount(val) {
        this.accountData.push(val)
      },
      // 删除一条入账
      deleteAccount(index) {
        if (this.accountData.length == 1) {
          if (this.accountData[0].money != '' || this.accountData[0].id != '') {
            this.accountData[0].money = ''
            this.accountData[0].id = ''
            this.accountData[0].edit = true
            this.accountData[0].add_time = dateForm(new Date())
          } else {
            this.$message.error('不能删除最后一行')
            // this.accountData[0].money = ''
            // this.accountData[0].id = ''
          }
        } else {
          this.accountData.splice(index, 1)
        }
      },
      close() {
        this.showDialog = false
      },
      timeChange(v) {
        console.log('v', v)
        let timeC = new Date(this.timebk).getTime()
        let vc = new Date(v).getTime()
        console.log('当前时间戳', timeC)
        console.log('选择的时间戳', vc)
        // if (vc > timeC) {
        //   this.$message.error('不能超过当前日期')
        //   this.endTime = JSON.parse(JSON.stringify(this.timebk))
        //   return false
        // }
      },
      handleExport() {
        if (this.type == 1) {
          // 对账
          downloadFile('/saleAdmin/duizhang/duizhang-export', '对账.xlsx', {
            user_id: this.paisongData.res_info.user_id,
            end_time: this.endTime,
          })
        } else {
          // 继续
          downloadFile('/saleAdmin/duizhang/log-export', '对账.xlsx', {
            duizhang_id: this.danjuData.res_info.duizhang_id,
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
